import React from 'react'

import { Image } from 'src/components/image'

export interface EnlightenmentProps {
  data: {
    title: string
    color: string
    subTitle?: string
    image: {
      imageId: string
      altText?: string
    }
  }
}

export const Enlightenment = ({ data }: EnlightenmentProps) => {
  const {
    title,
    color,
    image,
    subTitle
  } = data
  return (
    <div className={`bg--${color} color--lite-yellow`}>
      <div className='container--xl enlightenment outer mxa pt2 pb2 pt4--1000 pb3--1000 tc al p x'>
        <h1 className='s60 mt2 s120--1000'>{title}</h1>
        <div className='enlightenment__star mxa mt4 mb4 pr'>
          <Image className='x z1 pr' imageId={image.imageId} alt={title} />
          <svg className='enlightenment__rotate pa x y top left' width="503" height="501" viewBox="0 0 503 501" fill="none">
            <path d="M40.0413 169.618L166.419 204.389L70.0588 115.578L182.348 183.148L113.519 71.625L203.411 166.964L167.246 41.0357L228.071 157.094L227.235 26.0591L254.501 154.234L289.057 27.8347L280.709 158.609L348.1 46.2155L304.75 169.88L399.984 79.8323L324.846 187.205L440.837 126.175L339.548 209.328L467.692 181.845L347.735 234.622L478.547 242.699L348.775 261.14L472.552 304.24L342.638 286.982L450.19 361.854L329.736 310.195L413.112 411.319L311.075 329.094L364.034 448.925L287.982 342.222L306.641 471.937L262.185 348.672L245.182 478.601L235.638 347.885L184.198 468.436L210.303 340.011L128.222 442.197L187.992 325.554L81.3995 401.839L170.442 305.66L47.2079 350.33L158.897 281.72L28.1792 291.527L154.238 255.576L25.7396 229.751L156.794 229.134L40.0413 169.618Z" fill="#FAF1C6" />
          </svg>
        </div>
        {subTitle && (
          <span className='caps mt4 mt8--800 db pt3 founders'>{subTitle}</span>
        )}
      </div>
    </div>
  )
}

export default Enlightenment